import React from "react"
import styled from "styled-components"

import Layout from "../components/layout-v2"
import SEO from "../components/seo"

import LetsChat from "../svgs/hey-there-lets-chat.svg"
import ContactPeople from "../svgs/contact-people-v2.svg"
import CallIcon from "../svgs/call.svg"
import EmailIcon from "../svgs/email.svg"
import LocationIcon from "../svgs/location.svg"
import BackgroundOne from "../svgs/background-line01.svg"
import BackgroundTwo from "../svgs/background-line02.svg"

const ContactUsPage = () => {
  return (
    <Layout>
      <SEO
        title={"Contact Us"}
        description={"Give Us a Call!"}
        keywords={"Contact, White Lion"}
      />
      <div style={{ padding: "0 20px" }}>
        <TitleSection>
          <div class="lets-chat">
            <LetsChat />
          </div>
          <div class="contact-people">
            <ContactPeople />
          </div>
        </TitleSection>
        <ContactInfo>
          <h1>Give Us a Call</h1>
          <div>
            <div>
              <div class="image-container">
                <div class="call-icon">
                  <CallIcon />
                </div>
              </div>{" "}
              <a href="tel:+15124818819">(512) 481-8819</a>
            </div>
            <div>
              <div class="image-container">
                <div class="email-icon">
                  <EmailIcon />
                </div>
              </div>{" "}
              <a href="mailto:ask@wlion.com">ask@wlion.com</a>
            </div>
            <div>
              <div class="image-container">
                <div class="location-icon">
                  <LocationIcon />
                </div>
              </div>{" "}
              <p>
                <a
                  class="gray-color"
                  href="https://goo.gl/maps/fBK67WnPbQp8K61Q6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>White Lion Interactive</strong>
                  <br />
                  5203 Brodie Lane
                  <br />
                  Sunset Valley, Texas 78745
                </a>
              </p>
            </div>
          </div>
        </ContactInfo>
        <ContactForm>
          <h2>Or Send An Email.</h2>
          <div>
            <div class="background-one">
              <BackgroundOne />
            </div>
            <div class="background-two">
              <BackgroundTwo />
            </div>
            <form
              name="Contact"
              method="POST"
              netlify-honeypot="bot-field"
              action="/thank-you/"
              data-netlify="true"
            >
              <input
                type="hidden"
                name="form-name"
                value="Contact"
                aria-label="Input"
              />
              <p class="hidden">
                <label>
                  Don’t fill this out if you're human:{" "}
                  <input name="bot-field" aria-label="Input" />
                </label>
              </p>
              <p class="full-width">
                <label htmlFor="first-name">
                  First Name{" "}
                  <input
                    type="text"
                    name="first-name"
                    aria-label="Input"
                    required
                  />
                </label>
              </p>
              <p class="full-width">
                <label htmlFor="last-name">
                  Last Name{" "}
                  <input
                    type="text"
                    name="last-name"
                    aria-label="Input"
                    required
                  />
                </label>
              </p>
              <p class="full-width">
                <label htmlFor="company">
                  Company or Organization{" "}
                  <input type="text" name="company" aria-label="Input" />
                </label>
              </p>
              <p class="full-width">
                <label htmlFor="phone">
                  Phone{" "}
                  <input type="text" name="phone" aria-label="Input" required />
                </label>
              </p>
              <p class="full-width">
                <label htmlFor="email">
                  Email Address{" "}
                  <input
                    type="email"
                    name="email"
                    aria-label="Input"
                    required
                  />
                </label>
              </p>
              <p class="full-row">
                <label htmlFor="your-message">
                  Your Message
                  <textarea
                    name="your-message"
                    cols="40"
                    rows="10"
                    maxLength="140"
                    minLength="0"
                    aria-label="Input"
                  />
                </label>
              </p>
              <p class={"button"}>
                <button
                  type="submit"
                  name="submit"
                  class={"submit"}
                  aria-label="Send"
                >
                  Send Email
                </button>
              </p>
            </form>
          </div>
        </ContactForm>
      </div>
    </Layout>
  )
}

const TitleSection = styled.header`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
  .lets-chat {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: 700px;
    width: 100%;
    height: auto;
  }
  .contact-people {
    max-width: 1170px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  @media (max-width: 1000px) {
    max-width: 700px;
    padding: 45px;
    .lets-chat {
      max-width: 400px;
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    padding: 20px;
    .lets-chat {
      max-width: 250px;
    }
  }
`

const ContactInfo = styled.section`
  max-width: 930px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  h1 {
    font-family: "Nobel", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 120px;
    line-height: 144px;
    color: #2b2520;
  }
  > div {
    border-left: 10px solid #db4140;
    padding: 20px 0;
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px;
      .image-container {
        width: 225px;
        display: flex;
        justify-content: center;
        align-items: center;
        .call-icon {
          height: 90px;
          width: 89px;
        }
        .email-icon {
          width: 92px;
          height: 92px;
        }
        .location-icon {
          width: 128px;
          height: 104px;
        }
      }
      &:first-child {
        a {
          font-family: "Nobel", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 100px;
          line-height: 120px;
          color: #2b2520;
          text-decoration: none;
        }
      }
      &:nth-child(2) {
        a {
          font-family: "Nobel", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 100px;
          line-height: 120px;
          color: #db4140;
          text-decoration: none;
          border-bottom: 8px solid #db4140;
        }
      }
      &:last-child {
        margin-bottom: 0;
        p {
          font-family: "Work Sans";
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          color: #2b2520;
          margin-top: 0;
          margin-bottom: 0px;
          a {
            color: #2b2520;
            text-decoration: none;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    max-width: 700px;
    h1 {
      font-size: 60px;
      line-height: 1.3;
    }
    > div {
      > div {
        &:first-child {
          a {
            font-size: 50px;
            line-height: 1.2;
          }
        }
        &:nth-child(2) {
          a {
            font-size: 50px;
            line-height: 1.3;
          }
        }
        &:last-child {
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    h1 {
      font-size: 36px;
      text-align: center;
    }
    > div {
      border-left: none;
      > div {
        flex-direction: column;
        svg {
          margin-bottom: 20px;
        }
        &:first-child {
          a {
            font-size: 36px;
            text-align: center;
          }
        }
        &:nth-child(2) {
          a {
            font-size: 36px;
            text-align: center;
            border-bottom: 3px solid #db4140;
          }
        }
        &:last-child {
          p {
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
  }
`

const ContactForm = styled.section`
  max-width: 1160px;
  width: 100%;
  margin: 100px auto;
  padding: 0;
  h2 {
    font-family: "Nobel", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 100px;
    line-height: 120px;
    color: #2b2520;
  }
  > div {
    max-width: 970px;
    width: 100%;
    position: relative;
    margin-top: 200px;
    z-index: 2;
    .background-one {
      position: absolute;
      z-index: 1;
      top: 150px;
      left: -110px;
      width: 363px;
      height: 418px;
    }
    .background-two {
      position: absolute;
      z-index: 1;
      bottom: 80px;
      left: 50px;
      width: 307px;
      height: 445px;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 10px solid #db4140;
      z-index: 2;
    }
    form {
      background-color: #2b2520;
      position: relative;
      z-index: 3;
      top: -130px;
      left: 200px;
      padding: 130px 110px;
      p {
        label {
          font-family: "Work Sans";
          font-size: 24px;
          line-height: 30px;
          font-weight: 500;
          color: #fff;
          margin-top: 0;
          margin-bottom: 0px;
          display: flex;
          flex-direction: column;
        }
        input,
        select,
        textarea {
          font-size: 22px;
          padding: 0 0.65em;
          color: #555;
          height: 60px;
          margin-top: 10px;
          outline: 0;
          &:focus {
            outline: 3px solid #db4140;
          }
        }
        textarea {
          height: 250px;
          padding: 15px;
        }
        input[type="file"] {
          padding: 0;
          font-size: 16px;
          color: #fff;
        }
        &.button {
          width: 100%;
        }
        button[type="submit"] {
          font-family: "Work Sans";
          font-size: 24px;
          line-height: 30px;
          font-weight: 500;
          color: #fff;
          margin-top: 0;
          margin-bottom: 0px;
          padding: 20px 70px;
          text-align: center;
          background-color: #db4140;
          border: none;
          outline: 0;
          &:hover {
            cursor: pointer;
          }
        }
        &.hidden {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          height: 0;
          width: 0;
          z-index: -1;
        }
        &.full-row {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 1300px) {
    max-width: 700px;
    h2 {
      font-size: 60px;
      line-height: 1.3;
    }
    > div {
      margin-top: 60px;
      max-width: 700px;
      .background-one,
      .background-two {
        display: none;
      }
      form {
        top: -20px;
        left: 20px;
        padding: 60px 50px;
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 500px;
    margin: 50px auto;
    h2 {
      font-size: 36px;
      text-align: center;
    }
    > div {
      margin-top: 20px;
      form {
        top: auto;
        left: auto;
        padding: 30px 20px;
        p {
          button[type="submit"] {
            padding: 20px;
            width: 100%;
          }
        }
      }
    }
  }
`

export default ContactUsPage
